/* spell-checker: ignore codigo, configuracion, informacion, metodos, categorias, tamanios, administracion, farmacoterapeuticas, logisticas */
const urls = {

    // General Urls
    NONE                : "",
    SETTINGS            : "configuracion",

    // Auth Urls
    LOGIN               : "ingresar",
    PASS_RECOVER        : "recuperar",
    PASS_CODE           : "codigo",
    PASS_RESET          : "resetear",
    PASS_RESET_CODE     : "resetear/:code",
    PASS_CHANGE         : "cambiar",

    // Profile Urls
    PROFILE             : "perfil",

    // Dashboard Urls
    DASHBOARD           : "inicio",
    DASHBOARD_TAB       : "inicio/:dashboardTab",
    DAILY               : "diario",
    COMMERCIAL          : "comercial",
    OPERATIONS          : "operaciones",
    MAP                 : "mapa",

    // Business Urls
    BUSINESS            : "negocio",
    USERS               : "usuarios",
    CONTACTS            : "contactos",

    // Pharmacy Urls
    PHARMACIES          : "farmacias",
    PHARMACY            : "negocio/farmacias/:pharmacyID",
    PHARMACY_SUBSIDIARY : "negocio/farmacias/:pharmacyID/sucursales/:subsidiaryID",

    // Subsidiary Urls
    SUBSIDIARIES        : "sucursales",
    SUBSIDIARY          : "negocio/sucursales/:subsidiaryID",
    INFORMATION         : "informacion",
    GATEWAYS            : "gateways",
    SCHEDULES           : "horarios",

    // Order Urls
    ORDERS              : "ordenes",
    ORDERS_TAB          : "ordenes/:orderTab",
    ORDER               : "ordenes/:orderTab/:orderID",
    DELIVERED           : "entregadas",
    PENDING             : "pendientes",
    REJECTED            : "rechazadas",
    CANCELED            : "canceladas",
    RESERVES            : "reservas",
    ALL                 : "todas",

    // Catalog Urls
    CATALOG             : "catalogo",
    PRODUCTS            : "productos",
    PRODUCT             : "productos/:productID",
    MACRO_CATEGORIES    : "macro-categorias",
    CATEGORIES          : "categorias",
    SUB_CATEGORIES      : "sub-categorias",
    SPACES              : "espacios",
    BANNERS             : "banners",

    // Medicine Urls
    MEDICINES           : "medicamentos",
    MEDICINE            : "medicamentos/:medicineID",
    LABORATORIES        : "laboratorios",
    MONO_DRUGS          : "mono-drogas",
    DRUG_TYPES          : "tipos-drogas",
    SALE_TYPES          : "tipos-ventas",
    HEALTH_TYPES        : "tipos-salud",
    SIZE_TYPES          : "tipos-tamanios",
    SHAPE_TYPES         : "tipos-formas",
    POTENCY_TYPES       : "tipos-potencia",
    UNIT_TYPES          : "tipos-unidad",
    ADMIN_ROUTES        : "vias-administracion",
    PHARMA_ACTIONS      : "acciones-farmacoterapeuticas",

    // Change Urls
    CHANGE_PRODUCTS     : "cambios-productos",
    CHANGE_PRODUCTS_TAB : "cambios-productos/:changeProductTab",
    CHANGE_PRODUCT      : "cambios-productos/:changeProductTab/:productID",
    CHANGE_CATALOGS     : "cambios-catalogo",
    CHANGE_CATALOGS_TAB : "cambios-catalogo/:changeCatalogTab",
    CHANGE_UPDATES      : "cambios-actualizaciones",
    PROCESS             : "procesar",
    VALIDATE            : "validar",
    COMPLETE            : "complete",

    // Client Urls
    CLIENTS             : "clientes",

    // Logistic Urls
    RETIREMENT_TYPES    : "tipos-retiros",
    LOGISTICS           : "logisticas",
    LOGISTICS_REASONS   : "errores-logisticas",

    // Transaction Urls
    TRANSACTIONS        : "transacciones",
    TRANSACTION         : "transacciones/:orderID",
    TRANSACTION_TYPES   : "tipos-transacciones",
    PAYMENT_TYPES       : "tipos-pagos",
    PAYMENT_METHODS     : "metodos-pagos",
    CARD_TYPES          : "tipos-tarjetas",
    BANKS               : "bancos",
    COMMISSIONS         : "comisiones",

    // Setup Urls
    ADMINS              : "admins",
    OBJECTIVES          : "objetivos",
    HOLIDAYS            : "feriados",
    STATES              : "estados",
    STATE_GROUPS        : "grupos-estados",
    COVERAGE_TYPES      : "tipos-coberturas",
    REJECT_REASONS      : "motivos-rechazo",
    CANCEL_REASONS      : "motivos-cancelado",
    TOOLTIPS            : "tooltips",
    MEDIA               : "archivos",

    // Document Urls
    DOCUMENTS           : "documentos",
    DOCUMENT            : "documentos/:documentSlug",

    // Email Urls
    EMAIL_TEMPLATES     : "plantillas-emails",
    EMAIL_QUEUE         : "cola-emails",
    EMAIL_WHITE_LIST    : "emails-blancos",

    // Log Urls
    LOG_PROVIDERS       : "log-proveedores",
    LOG_PROVIDER        : "log-proveedores/:logID",
    LOG_ACTIONS         : "log-acciones",
    LOG_ERRORS          : "log-errores",
    LOG_ERROR           : "log-errores/:logID",

};

export default urls;
