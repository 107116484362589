import React                from "react";
import Navigate             from "Dashboard/Core/Navigate";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";

// Components
import PharmacyCode         from "./PharmacyCode";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Content              from "Dashboard/Components/Core/Content";
import Header               from "Dashboard/Components/Header/Header";
import ActionList           from "Dashboard/Components/Header/ActionList";
import ActionItem           from "Dashboard/Components/Header/ActionItem";
import PageContainer        from "Dashboard/Components/Page/PageContainer";
import PageColumns          from "Dashboard/Components/Page/PageColumns";
import PageFooter           from "Dashboard/Components/Page/PageFooter";
import InputField           from "Dashboard/Components/Form/InputField";
import Button               from "Dashboard/Components/Form/Button";
import DeleteDialog         from "Dashboard/Components/Dialogs/DeleteDialog";



/**
 * The Pharmacy Page
 * @returns {React.ReactElement}
 */
function PharmacyPage() {
    const { pharmacyID } = Navigate.useParams();

    const navigate = Navigate.useGoto();
    const parent   = Navigate.useParent(2);

    const { charging, edition, elem } = Store.useState("pharmacy");
    const { fetchElem, editElem, deleteElem } = Store.useAction("pharmacy");


    // The Current State
    const [ showCode,   setShowCode   ] = React.useState(false);
    const [ showDelete, setShowDelete ] = React.useState(false);

    // The Initial Data
    const initialData = {
        pharmacyID        : 0,
        name              : "",
        image             : "",
        color             : "",
        hasWebChat        : 0,
        webChatID         : "",
        forTesting        : 0,
        subsidiaryTesting : 0,
        orderTesting      : 0,
    };

    // Handles the Delete
    const handleDelete = async () => {
        await deleteElem(elem.id);
        navigate(parent);
    };

    // The Form State
    const {
        loading, data, errors, setElem, handleChange, handleSubmit,
    } = useForm("pharmacy", initialData, editElem);


    // Load the Data
    React.useEffect(() => {
        if (pharmacyID) {
            fetchElem(pharmacyID);
        }
    }, [ pharmacyID ]);

    // Store the Data
    React.useEffect(() => {
        setElem({ ...elem });
    }, [ edition ]);



    // Do the Render
    return <Main>
        <Header message="GENERAL_INFO" icon="pharmacy">
            <ActionList>
                <ActionItem
                    isHidden={!data.hasWebChat}
                    icon="code"
                    message="PHARMACIES_CODE_TITLE"
                    onClick={() => setShowCode(true)}
                />
            </ActionList>
        </Header>

        <Content isLoading={charging || loading}>
            <PageContainer error={errors.form}>
                <PageColumns amount="1">
                    <InputField
                        name="name"
                        label="GENERAL_NAME"
                        value={data.name}
                        error={errors.name}
                        onChange={handleChange}
                        isRequired
                    />
                    <InputField
                        name="image"
                        label="GENERAL_IMAGE_URL"
                        value={data.image}
                        error={errors.image}
                        onChange={handleChange}
                    />
                    <InputField
                        type="color"
                        name="color"
                        label="GENERAL_COLOR"
                        value={data.color}
                        error={errors.color}
                        onChange={handleChange}
                    />

                    <InputField
                        type="toggle"
                        name="hasWebChat"
                        label="PHARMACIES_HAS_WEB_CHAT"
                        value={data.hasWebChat}
                        error={errors.hasWebChat}
                        onChange={handleChange}
                        withBorder
                    />
                    <InputField
                        isHidden={!data.hasWebChat}
                        name="webChatID"
                        label="PHARMACIES_WEB_CHAT_ID"
                        helperText="PHARMACIES_WEB_CHAT_ID_HELP"
                        value={data.webChatID}
                        error={errors.webChatID}
                        onChange={handleChange}
                    />

                    <InputField
                        type="toggle"
                        name="forTesting"
                        label="PHARMACIES_FOR_TESTING"
                        value={data.forTesting}
                        error={errors.forTesting}
                        onChange={handleChange}
                        withBorder
                    />
                    <InputField
                        type="toggle"
                        name="subsidiaryTesting"
                        label="PHARMACIES_SUBSIDIARY_TESTING"
                        value={data.subsidiaryTesting}
                        error={errors.subsidiaryTesting}
                        onChange={handleChange}
                        withBorder
                    />
                    <InputField
                        type="toggle"
                        name="orderTesting"
                        label="PHARMACIES_ORDER_TESTING"
                        value={data.orderTesting}
                        error={errors.orderTesting}
                        onChange={handleChange}
                        withBorder
                    />
                </PageColumns>

                <PageFooter>
                    <Button
                        variant="primary"
                        message="GENERAL_SAVE"
                        onClick={() => handleSubmit()}
                        isDisabled={loading}
                    />
                    <Button
                        variant="primary"
                        message="GENERAL_DELETE"
                        onClick={() => setShowDelete(true)}
                    />
                </PageFooter>
            </PageContainer>
        </Content>

        <PharmacyCode
            open={showCode}
            elem={elem}
            onClose={() => setShowCode(false)}
        />
        <DeleteDialog
            open={showDelete}
            title="PHARMACIES_DELETE_TITLE"
            message="PHARMACIES_DELETE_TEXT"
            content={data.name}
            onSubmit={handleDelete}
            onClose={() => setShowDelete(false)}
        />
    </Main>;
}

export default PharmacyPage;
