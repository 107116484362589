import React                from "react";
import Styled               from "styled-components";
import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";

// Components
import ViewColumns          from "Components/Utils/View/ViewColumns";
import ViewItem             from "Components/Utils/View/ViewItem";

// Dashboard
import HyperLink            from "Dashboard/Components/Link/HyperLink";



// Styles
const Container = Styled(ViewColumns)`
    grid-area: general;
`;



/**
 * The Order General
 * @returns {React.ReactElement}
 */
function OrderGeneral() {
    const { elem } = Store.useState("order");


    // Do the Render
    return <Container message="ORDERS_ORDER_INFO" amount="2">
        <ViewItem
            message="GENERAL_CREATED_AT_FEM"
            value={elem.createdTimeDate}
        />
        <ViewItem message="ORDERS_NUMBER">
            {elem.numberText}
            {elem.forTesting && <b className="left-space text-red">
                {NLS.get("PHARMACIES_ORDER_TESTING")}
            </b>}
        </ViewItem>

        <ViewItem
            message="SUBSIDIARIES_SINGULAR"
            value={elem.subsidiaryFullName}
        />
        <ViewItem
            message="PHARMACIES_SINGULAR"
            value={elem.pharmacyName}
        />

        <ViewItem
            message="COVERAGE_TYPES_SINGULAR"
            value={elem.coverageTypeName}
        />
        <ViewItem
            isHidden={!elem.coverageName}
            message="ORDERS_COVERAGE_NAME"
            value={elem.coverageName}
        />
        <ViewItem
            isHidden={!elem.coveragePlan}
            message="ORDERS_COVERAGE_PLAN"
            value={elem.coveragePlan}
        />
        <ViewItem
            isHidden={!elem.coverageCredential}
            message="ORDERS_COVERAGE_CREDENTIAL"
            value={elem.coverageCredential}
        />

        <ViewItem
            isHidden={!elem.gatewayCode}
            message="PAYMENT_TYPES_SINGULAR"
        >
            {elem.gatewayName}
            <HyperLink
                isHidden={!elem.paymentUrl || !!elem.paidTime || !!elem.canceledTime}
                className="left-space"
                url={elem.paymentUrl}
                target="_blank"
                message="ORDERS_PAYMENT_LINK"
            />
        </ViewItem>
        <ViewItem
            isHidden={!elem.paymentExternalID}
            message="ORDERS_PAYMENT_EXTERNAL_ID"
            value={elem.paymentExternalID}
        />

        <ViewItem
            isHidden={!elem.activationTime}
            message="ORDERS_ACTIVATES_IN"
            value={elem.activationTimeText}
        />
        <ViewItem
            isHidden={!elem.notificationTime}
            message="ORDERS_NOTIFIES_IN"
            value={elem.notificationTimeText}
        />
        <ViewItem
            isHidden={!elem.expirationTime}
            message="ORDERS_EXPIRES_IN"
            value={elem.expirationTimeText}
        />
        <ViewItem
            isHidden={!elem.products}
            className="columns-double"
            message="ORDERS_CLIENT_PRODUCTS"
            value={elem.products}
        />

        <ViewItem
            isHidden={!elem.ticketNumber}
            message="ORDERS_TICKET_NUMBER"
            value={elem.ticketNumber}
        />
        <ViewItem
            isHidden={!elem.invoiceNumber}
            message="ORDERS_INVOICE_NUMBER"
            value={elem.invoiceNumber}
        />
    </Container>;
}

export default OrderGeneral;
