import React                from "react";
import Store                from "Dashboard/Core/Store";
import Navigate             from "Dashboard/Core/Navigate";
import Utils                from "Dashboard/Utils/Utils";

// Components
import ViewTable            from "Components/Utils/View/ViewTable";

// Dashboard
import Table                from "Dashboard/Components/Table/Table";
import TableHead            from "Dashboard/Components/Table/TableHead";
import TableBody            from "Dashboard/Components/Table/TableBody";
import TableRow             from "Dashboard/Components/Table/TableRow";
import TableHeader          from "Dashboard/Components/Table/TableHeader";
import TableCell            from "Dashboard/Components/Table/TableCell";
import TableActionList      from "Dashboard/Components/Table/TableActionList";
import TableAction          from "Dashboard/Components/Table/TableAction";
import Html                 from "Dashboard/Components/Common/Html";
import Image                from "Dashboard/Components/Common/Image";



/**
 * The Order Children
 * @returns {React.ReactElement}
 */
function OrderChildren() {
    const { orders } = Store.useState("order");
    const navigate   = Navigate.useGoto();


    // Handles the Order Goto
    const handleAction = (action, elemID) => {
        const elem = Utils.getValue(orders, "orderID", elemID);
        const url  = elem.isOrder ? "DELIVERED" : "PENDING";
        navigate("ORDERS", url, elemID);
    };


    // Do the Render
    if (!orders.length) {
        return <React.Fragment />;
    }
    return <ViewTable message="ORDERS_NAME">
        <Table none="ORDERS_NONE_AVAILABLE" noSorting notFixed>
            <TableHead>
                <TableHeader field="subsidiaryImage"    message=""                          noSpace maxWidth="40" />
                <TableHeader field="subsidiaryFullName" message="SUBSIDIARIES_SINGULAR"     isFlex />
                <TableHeader field="orderNumber"        message="GENERAL_ID"                isFlex />
                <TableHeader field="retirementTypeName" message="RETIREMENT_TYPES_SINGULAR" isFlex />
                <TableHeader field="orderStateName"     message="GENERAL_STATUS"            isFlex />
                <TableHeader field="total"              message="GENERAL_TOTAL"             isFlex align="right" maxWidth="80" />
                <TableHeader field="commission"         message="ORDERS_COMMISSION"         isFlex align="right" maxWidth="100" />
                <TableHeader field="mercadoPagoFee"     message="GENERAL_MP"                isFlex align="right" maxWidth="100" />
            </TableHead>
            <TableBody>
                {orders.map((elem) => <TableRow key={elem.orderID} elemID={elem.orderID}>
                    <TableCell>
                        <Image
                            source={elem.subsidiaryImage}
                            message={elem.subsidiaryFullName}
                            size="40"
                        />
                    </TableCell>
                    <TableCell message={elem.subsidiaryFullName} />
                    <TableCell message={elem.orderNumber}        />
                    <TableCell message={elem.retirementTypeName} />
                    <TableCell message={elem.orderStateName}     />
                    <TableCell><Html>{elem.netTotalText}</Html></TableCell>
                    <TableCell><Html>{elem.commissionText}</Html></TableCell>
                    <TableCell><Html>{elem.mercadoPagoText}</Html></TableCell>
                </TableRow>)}
            </TableBody>
            <TableActionList onAction={handleAction}>
                <TableAction action="VIEW" message="ORDERS_VIEW_TITLE" />
            </TableActionList>
        </Table>
    </ViewTable>;
}

export default OrderChildren;
