import React                from "react";
import Action               from "Dashboard/Core/Action";
import Navigate             from "Dashboard/Core/Navigate";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import useList              from "Dashboard/Hooks/List";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Content              from "Dashboard/Components/Core/Content";
import Header               from "Dashboard/Components/Header/Header";
import Filter               from "Dashboard/Components/Filter/Filter";
import Table                from "Dashboard/Components/Table/Table";
import TableHead            from "Dashboard/Components/Table/TableHead";
import TableBody            from "Dashboard/Components/Table/TableBody";
import TableRow             from "Dashboard/Components/Table/TableRow";
import TableHeader          from "Dashboard/Components/Table/TableHeader";
import TableCell            from "Dashboard/Components/Table/TableCell";
import TablePaging          from "Dashboard/Components/Table/TablePaging";
import TableActionList      from "Dashboard/Components/Table/TableActionList";
import TableAction          from "Dashboard/Components/Table/TableAction";



/**
 * The Change Update List
 * @returns {React.ReactElement}
 */
function ChangeUpdateList() {
    const { load, loadFilter } = useList("changeUpdate");

    const navigate = Navigate.useGoto();

    const data = Store.useState("changeUpdate");
    const { loading, canEdit, list, total, filters, sort } = data;


    // The Current State
    const [ , updateAction ] = Action.useAction();


    // Handles the Action
    const handleAction = (action, elemID) => {
        console.log(elemID);
        if (action.isView) {
            updateAction();
            const productID = Utils.getValue(list, "id", elemID, "productID");
            navigate("CATALOG", "PRODUCTS", productID);
        } else {
            updateAction(action, elemID);
        }
    };


    // Do the Render
    return <Main>
        <Header message="CHANGE_UPDATES_NAME" icon="change-update" />
        <Content>
            <Filter
                onFilter={loadFilter}
                values={filters}
                hasSearch
            />
            <Table
                fetch={load}
                sort={sort}
                none="CHANGE_UPDATES_NONE_AVAILABLE"
                isLoading={loading}
            >
                <TableHead>
                    <TableHeader field="createdTime" message="GENERAL_CREATED_AT_FEM" maxWidth="160"/>
                    <TableHeader field="createdUser" message="USERS_SINGULAR"         />
                    <TableHeader field="productName" message="PRODUCTS_NAME"          grow="2" />
                    <TableHeader field="field"       message="PRODUCTS_FIELD"         />
                    <TableHeader field="oldValue"    message="PRODUCTS_OLD_VALUE"     />
                    <TableHeader field="newValue"    message="PRODUCTS_NEW_VALUE"     />
                </TableHead>
                <TableBody>
                    {list.map((elem) => <TableRow key={elem.changeUpdateID} elemID={elem.changeUpdateID}>
                        <TableCell message={elem.createdDate}     />
                        <TableCell message={elem.createdUserName} />
                        <TableCell message={elem.productName}     />
                        <TableCell message={elem.field}           />
                        <TableCell message={elem.oldValue}        />
                        <TableCell message={elem.newValue}        />
                    </TableRow>)}
                </TableBody>
                <TablePaging total={total} />
                <TableActionList onAction={handleAction} canEdit={canEdit}>
                    <TableAction action="VIEW" message="PRODUCTS_VIEW_TITLE" />
                </TableActionList>
            </Table>
        </Content>
    </Main>;
}

export default ChangeUpdateList;
