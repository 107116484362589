import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import DialogMessage        from "Dashboard/Components/Dialog/DialogMessage";
import InputField           from "Dashboard/Components/Form/InputField";
import InputItem            from "Dashboard/Components/Form/InputItem";



/**
 * The Order Retired Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function OrderRetired(props) {
    const { open, elem, onClose, onSubmit } = props;

    const { retiredElem } = Store.useAction("order");


    // The Initial Data
    const initialData = {
        approvedDate : "",
        approvedTime : "",
        retireDate   : "",
        retireTime   : "",
        retiredDate  : "",
        retiredTime  : "",
    };

    // Handle the Retired
    const handleEdit = (data) => {
        return retiredElem({ ...data, orderID : elem.id });
    };

    // The Form State
    const {
        loading, data, errors, handleChange, handleSubmit,
    } = useForm("order", initialData, handleEdit, onSubmit);


    // Do the Render
    return <EditDialog
        open={open}
        icon="check"
        title="ORDERS_RETIRED_TITLE"
        error={errors.form}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
        isNarrow
    >
        <DialogMessage
            variant="h3"
            message="ORDERS_RETIRED_TEXT"
            noSpace
        />

        <InputField
            isHidden={elem.approvedTime > 0}
            type="double"
            helperText="ORDERS_EMPTY_DATE_TIP"
            error={errors.approvedDate}
            onChange={handleChange}
        >
            <InputItem
                type="date"
                name="approvedDate"
                label="ORDERS_APPROVED_DATE"
                value={data.approvedDate}
                maxValue="2999-12-31"
            />
            <InputItem
                type="time"
                name="approvedTime"
                label="ORDERS_APPROVED_TIME"
                value={data.approvedTime}
            />
        </InputField>

        <InputField
            isHidden={elem.retireTime > 0}
            type="double"
            helperText="ORDERS_EMPTY_DATE_TIP"
            error={errors.retireDate}
            onChange={handleChange}
        >
            <InputItem
                type="date"
                name="retireDate"
                label="ORDERS_RETIRE_DATE"
                value={data.retireDate}
                maxValue="2999-12-31"
            />
            <InputItem
                type="time"
                name="retireTime"
                label="ORDERS_RETIRE_TIME"
                value={data.retireTime}
            />
        </InputField>

        <InputField
            isHidden={elem.retiredTime > 0}
            type="double"
            helperText="ORDERS_EMPTY_DATE_TIP"
            error={errors.retiredDate}
            onChange={handleChange}
        >
            <InputItem
                type="date"
                name="retiredDate"
                label="ORDERS_RETIRED_DATE"
                value={data.retiredDate}
                maxValue="2999-12-31"
            />
            <InputItem
                type="time"
                name="retiredTime"
                label="ORDERS_RETIRED_TIME"
                value={data.retiredTime}
            />
        </InputField>
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
OrderRetired.propTypes = {
    open     : PropTypes.bool.isRequired,
    onClose  : PropTypes.func.isRequired,
    onSubmit : PropTypes.func.isRequired,
    elem     : PropTypes.object,
};

export default OrderRetired;
